import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import Seo from '../components/Seo';

const Error = () => {
	return (
		<Wrapper>
			<Layout>
				<Seo title="Page not found" />
				<main className="error-page">
					<div className="error-container">
						<h1>404 Error</h1>
						<p>
							Sorry - we can't find that page.
							<br />
							Perhaps there is a typo. Otherwise, it must have been deleted or
							moved
						</p>
						<Link to="/" className="btn">
							back home
						</Link>
					</div>
				</main>
			</Layout>
		</Wrapper>
	);
};

export default Error;

const Wrapper = styled.div`
	.error-page {
		display: grid;
		place-items: center;
		background: var(--clr-primary-10);
		text-align: center;
		min-height: 80vh;
	}
	.error-page h1 {
		text-transform: uppercase;
		color: var(--clr-primary-1);
		margin-bottom: 3.2rem;
	}
`;
